const roleAdmin = ['Administrator']
const roleAdminOrBackOffice = [...roleAdmin, 'Back Office Staff']

const roleGangerOrWorker = ['Ganger', 'Worker', ...roleAdminOrBackOffice]

const MenuItems = [
  {
    title: 'Dashboard',
    caption: 'quasar.dev',
    icon: 'dashboard',
    link: '/',
    requiresAnyOfRoles: roleAdminOrBackOffice
  },
  {
    title: 'Employees',
    caption: 'github.com/quasarframework',
    icon: 'people',
    link: '/employee-management',
    requiresAnyOfRoles: roleAdminOrBackOffice
  },
  {
    title: 'Employee Leave',
    caption: 'github.com/quasarframework',
    icon: 'people',
    link: '/employee-leave-management',
    requiresAnyOfRoles: roleAdminOrBackOffice
  },
  {
    title: 'Users',
    caption: 'github.com/quasarframework',
    icon: 'account_circle',
    link: '/user-management',
    requiresAnyOfRoles: roleAdmin
  },
  {
    title: 'Clients',
    caption: 'github.com/quasarframework',
    icon: 'groups',
    link: '/client-management',
    requiresAnyOfRoles: roleAdminOrBackOffice
  },
  {
    title: 'Jobs',
    caption: 'github.com/quasarframework',
    icon: 'work',
    link: '/job-management',
    requiresAnyOfRoles: roleAdminOrBackOffice
  },
  {
    title: 'Planning',
    caption: 'github.com/quasarframework',
    icon: 'date_range',
    link: '/planning-sheet',
    requiresAnyOfRoles: roleAdminOrBackOffice
  },
  {
    title: 'Fatigue Assessment',
    caption: 'github.com/quasarframework',
    icon: 'free_breakfast',
    link: '/fatigue-assessment',
    requiresAnyOfRoles: roleAdminOrBackOffice
  },
  {
    title: 'Timesheet',
    caption: 'github.com/quasarframework',
    icon: 'access_time_filled',
    link: '/timesheet',
    requiresAnyOfRoles: roleAdminOrBackOffice
  },
  {
    title: 'Fill Timesheet',
    caption: 'github.com/quasarframework',
    icon: 'access_time_filled',
    link: '/fill-timesheet'
    // requiresAnyOfRoles: roleGangerOrWorker
  },
  {
    title: 'Checklist Questions',
    caption: 'github.com/quasarframework',
    icon: 'checklist',
    link: '/pscl-question-management',
    requiresAnyOfRoles: roleAdminOrBackOffice
  },
  {
    title: 'Documents',
    caption: 'github.com/quasarframework',
    icon: 'folder_open',
    link: '/document-management',
    requiresAnyOfRoles: roleAdminOrBackOffice
  },
  {
    title: 'Public Form Submissions',
    icon: 'summarize',
    link: '/public-form-submissions/',
    requiresAnyOfRoles: roleAdminOrBackOffice
  },
  {
    title: 'Reports',
    icon: 'description',
    link: '/reports/',
    requiresAnyOfRoles: roleAdminOrBackOffice
  },
  {
    title: 'Configuration',
    caption: 'github.com/quasarframework',
    icon: 'settings',
    link: '/configuration/',
    requiresAnyOfRoles: roleAdmin
  }
]

export default MenuItems

export { roleAdminOrBackOffice, roleAdmin, roleGangerOrWorker }
